import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Renderer } from "marked";
import { renderToStaticMarkup } from "react-dom/server";
import { Table, TableBody, TableCell, TableHead, TableRow, } from "@mui/material";
import "./Markdown.css";
import { getSubdomain } from "../utils";
import { VALID_SUBDOMAIN_PREFIXES } from "../constants";
// TODO: Using any Material-UI component seems to break the global styles for whatever reason.
//       I replaced everything except the Table components with CSS-only styles.
//       In case someone complains about broken fonts after viewing markdown content with tables
//       you have to replace the MUI table rendering with a CSS only version
const renderLinkCommon = (href, title, text) => {
    const isCurrentApp = href && href.startsWith(window.location.origin);
    const isApp = href &&
        [...VALID_SUBDOMAIN_PREFIXES, null].find((subdomain) => href.startsWith(`${window.location.protocol}//${getSubdomain(subdomain)}`)) !== undefined;
    return renderToStaticMarkup(href && isCurrentApp ? (_jsx("a", { href: href.substring(window.location.origin.length), title: title ?? undefined, className: "md-body", dangerouslySetInnerHTML: { __html: text } })) : (_jsx("a", { href: href ?? undefined, title: title ?? undefined, className: "md-body", dangerouslySetInnerHTML: { __html: text }, target: isApp ? undefined : "_blank", rel: "noreferrer" })));
};
const DefaultMarkedRenderer = {
    heading({ tokens, depth: level }) {
        const text = this.parser.parseInline(tokens);
        return renderToStaticMarkup(React.createElement(("h" + level), {
            className: "md-content-h" + level,
            dangerouslySetInnerHTML: { __html: text },
        }));
    },
    link({ href, title, tokens }) {
        const text = this.parser.parseInline(tokens);
        return renderLinkCommon(href, title, text);
    },
    paragraph({ tokens }) {
        const text = this.parser.parseInline(tokens);
        return renderToStaticMarkup(_jsx("p", { className: "md-body", dangerouslySetInnerHTML: { __html: text } }));
    },
};
export class JournalRenderer extends Renderer {
    link({ href, title, tokens }) {
        const text = this.parser.parseInline(tokens);
        return renderLinkCommon(href, title, text);
    }
    strong({ tokens }) {
        const text = this.parser.parseInline(tokens);
        return renderToStaticMarkup(_jsx("strong", { className: "md-body md-strong", dangerouslySetInnerHTML: { __html: text } }));
    }
    paragraph({ tokens }) {
        const text = this.parser.parseInline(tokens);
        return renderToStaticMarkup(_jsx("p", { className: "md-body", dangerouslySetInnerHTML: { __html: text } }));
    }
    heading({ depth: level, tokens }) {
        const text = this.parser.parseInline(tokens);
        return renderToStaticMarkup(React.createElement(("h" + level), {
            className: "md-h" + level,
            dangerouslySetInnerHTML: { __html: text },
        }));
    }
    table({ header, rows }) {
        return renderToStaticMarkup(_jsxs(Table, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: header.map((entry, idx) => (_jsx(TableCell, { align: entry.align ?? undefined, variant: entry.header ? "head" : "body", dangerouslySetInnerHTML: {
                                __html: this.parser.parseInline(entry.tokens),
                            } }, idx))) }) }), _jsx(TableBody, { children: rows.map((row, rowId) => (_jsx(TableRow, { children: row.map((entry, cellId) => (_jsx(TableCell, { align: entry.align ?? undefined, variant: entry.header ? "head" : "body", dangerouslySetInnerHTML: {
                                __html: this.parser.parseInline(entry.tokens),
                            } }, cellId))) }, rowId))) })] }));
    }
    tablerow({ text }) {
        return renderToStaticMarkup(_jsx(TableRow, { dangerouslySetInnerHTML: { __html: text } }));
    }
    tablecell({ tokens, header, align }) {
        const text = this.parser.parseInline(tokens);
        return renderToStaticMarkup(_jsx(TableCell, { variant: header ? "head" : "body", dangerouslySetInnerHTML: { __html: text }, align: align ?? undefined }));
    }
    list({ items, ordered, start }) {
        return renderToStaticMarkup(ordered ? (_jsx("ol", { className: "md-list md-ol", start: start || undefined, dangerouslySetInnerHTML: {
                __html: items.map(this.listitem.bind(this)).join(""),
            } })) : (_jsx("ul", { className: "md-list md-ul", dangerouslySetInnerHTML: {
                __html: items.map(this.listitem.bind(this)).join(""),
            } })));
    }
    listitem({ tokens, task, checked, loose }) {
        const text = this.parser.parse(tokens, loose);
        return renderToStaticMarkup(_jsxs("li", { className: "md-li", children: [task && (_jsx("div", { className: "md-li-task-box", children: checked && (_jsx("svg", { viewBox: "-3.5 -4.5 24 24", className: "md-li-task-box-checked", children: _jsx("polyline", { id: "check", fill: "transparent", stroke: "currentColor", strokeWidth: "3.5", points: "1 7 6 12 16.5 1.5" }) })) })), _jsx("span", { dangerouslySetInnerHTML: { __html: text } })] }));
    }
    image({ href, title, text }) {
        return renderToStaticMarkup(_jsx("img", { src: href ?? undefined, title: title ?? undefined, alt: text, className: "md-img" }));
    }
    blockquote({ tokens }) {
        const text = this.parser.parse(tokens);
        return renderToStaticMarkup(_jsx("blockquote", { dangerouslySetInnerHTML: { __html: text }, className: "md-blockquote" }));
    }
    code({ text, lang }) {
        return renderToStaticMarkup(_jsx("code", { className: "md-code md-code-block md-code-lang-" + lang, children: text }));
    }
    codespan({ text }) {
        return renderToStaticMarkup(_jsx("code", { className: "md-code", children: text }));
    }
}
export default DefaultMarkedRenderer;
